import React from 'react';
import classNames from 'classnames';
import { useAppendAd } from 'js/components/ui/PrBanner/hooks/useAppendAd';

export type BannerSizeType = '320x100' | '300x250' | '336x280' | '728x90';
export type BannerPosition = 'right';

export type Props = {
  size: BannerSizeType;
  adInnerHtml: string;
  hasBorder?: boolean;
  position?: BannerPosition;
  prColor?: 'black' | 'white';
  className?: string;
  style?: React.CSSProperties;
};

export const PrBannerPresenter: React.FC<Props> = ({
  size,
  adInnerHtml,
  hasBorder,
  position,
  prColor = 'black',
  className,
  style,
}) => {
  const { appendElementRef } = useAppendAd<HTMLDivElement>(adInnerHtml);
  return (
    <div
      className={classNames(
        'c_adnw',
        `c_${size}`,
        {
          'has-border': hasBorder,
          [`position-${position}`]: position,
        },
        className,
      )}
      {...{ style }}
    >
      <div ref={appendElementRef} className="c_adnw__ad-wrapper" />
      <span
        className={classNames('c_adnw__pr', {
          'color-white': prColor === 'white',
        })}
      >
        この広告はECナビポイント加算対象外です。
      </span>
    </div>
  );
};
